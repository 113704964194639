<template>
  <div id="app">
    <keep-alive >
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
      <!-- <router-view></router-view> -->
  </div>
</template>

<script>

export default {
  name: 'App',
  data () {
    return {

    }
  },
  mounted () {
    window.allChangeType = this.allChangeType;
  },
  methods: {
    allChangeType (typeId) {
      const {$router} = this;
      const {token, position} = this.$route.query;
      $router.push({name:'index', query:{token, position, typeId}})
    }
  }
}
</script>

<style>
body{
    background:rgb(red);
}
h4, p{
  margin: 0;
}
</style>
