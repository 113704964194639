export default [
    {
        path: '/liangzhu/redcardindex',
        component: () => import('@/components/drawRedEnvelopes/index'),
        name: 'redCardIndex',
        meta: {
            title: '抽红包',
            keepAlive: true
        }
    },
    {
        path: '/liangzhu/luckdraw',
        component: () => import('@/components/drawRedEnvelopes/luckDraw'),
        name: 'luckDraw',
        meta: {
            title: '抽红包',
            keepAlive: true
        }
    }
]