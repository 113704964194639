import Vue from 'vue'
import Router from 'vue-router'
import drawRedEnvelopes from './drawRedEnvelopes'


Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/liangzhu/index',
            component: () => import('@/components/index.vue'),
            name: 'index',
            meta: {
                title: '良助',
                keepAlive: true,
                scrollTop:0,
            }
        },
        {
            path: '/liangzhu/collection',
            component: () => import('@/components/collection.vue'),
            name: 'collection',
            meta: {
                title: '收藏',
                keepAlive: true
            }
        },
        {
            path: '/liangzhu/productdetails',
            component: () => import('@/components/productDetails.vue'),
            name: 'productDetails',
            meta: {
                title: '产品详情',
                keepAlive: false
            }
        },
        {
            path: '/liangzhu/gadgets',
            component: () => import('@/components/gadgets.vue'),
            name: 'gadgets',
            meta: {
                title: '小工具',
                keepAlive: true
            }
        },
        {
            path: '/liangzhu/emptypage',
            component: () => import('@/components/emptyPage.vue'),
            name: 'emptyPage',
            meta: {
                title: '',
                keepAlive: true
            }
        },
        {
            path: '/liangzhu/search',
            component: () => import('@/components/search.vue'),
            name: 'search',
            meta: {
                title: '搜索',
                keepAlive: true
            }
        },
        {
            path: '/liangzhu/eggs',
            component: () => import("@/components/eggs.vue"),
            name: 'eggs',
            meta: {
                title: '砸金蛋',
                keepAlive: true
            } 
        },
        {
            path: '/liangzhu/wxeggs',
            component: () => import("@/components/wxeggs.vue"),
            name: 'wxeggs',
            meta: {
                title: '砸金蛋',
                keepAlive: true
            }
        },
        {
            path: '/liangzhu/entrance',
            component: () => import("@/components/entrance.vue"),
            name: 'entrance',
            meta: {
                title: '百万豪礼',
                keepAlive: true
            }
        },
        {
            path: '/liangzhu/magiccard',
            component: () => import("@/components/magicCard.vue"),
            name: 'magicCard',
            meta: {
                title: '百变魔卡',
                keepAlive: true
            }
        },
        ...drawRedEnvelopes
    ]
})