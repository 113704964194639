import {Progress, Skeleton, DropdownMenu, DropdownItem, Col, Row, Swipe, SwipeItem, Lazyload, Sidebar, SidebarItem, List, Toast, Popup, Tab, Tabs } from 'vant';


export default {
    install: function (Vue) {
        Vue.use(Col);
        Vue.use(Row);
        Vue.use(Lazyload, {
            lazyComponent: true,
        });
        Vue.use(Sidebar);
        Vue.use(SidebarItem);
        Vue.use(List);
        Vue.use(Toast);
        Vue.use(Popup);
        Vue.use(DropdownMenu);
        Vue.use(DropdownItem);
        Vue.use(Skeleton);
        Vue.use(Progress);
        Vue.use(Tab);
        Vue.use(Tabs);
        Vue.use(Swipe);
        Vue.use(SwipeItem);
    }
}