import Vue from 'vue'
import App from './App.vue'
import Vant from './vant'
import 'vant/lib/button/style'
import router from './router'
import 'amfe-flexible'
import VueMasonry from 'vue-masonry-css'

Vue.config.productionTip = false

Vue.use(Vant)
Vue.use(VueMasonry);

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }

  if (from.meta.keepAlive) {
    const $content = document.querySelector('.productList');
    const scrollTop = $content ? $content.scrollTop : 0;
    from.meta.scrollTop = scrollTop;
  }
  next();
})


new Vue({
  render: h => h(App),
  router
}).$mount('#app')
